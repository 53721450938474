import React, { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';

const Arc = ({ width, percent }) => {
  const arcWidth = width;
  const arcHeight = arcWidth / 2;
  const arcX = arcWidth / 2;
  const arcY = arcWidth / 2;

  const arcBackgroundLineWidth = 26;
  const arcPrimaryLineWidth = arcBackgroundLineWidth * (2 / 6);
  const arcSecondaryLineWidth = arcBackgroundLineWidth * (4 / 6);
  const segmentedArcLineWidth = arcBackgroundLineWidth * 0.5;

  const radiusOffset = 3;
  const arcBackgroundRadius =
    arcHeight -
    arcBackgroundLineWidth * 0.5 -
    segmentedArcLineWidth -
    radiusOffset;
  const arcPrimaryRadius =
    arcHeight -
    arcPrimaryLineWidth * 0.5 -
    segmentedArcLineWidth -
    radiusOffset;
  const arcSecondaryRadius =
    arcHeight -
    arcPrimaryLineWidth -
    arcSecondaryLineWidth * 0.5 -
    segmentedArcLineWidth -
    radiusOffset;
  const segmentedArcRadius = arcHeight - segmentedArcLineWidth * 0.5;

  const backgroundCanvasRef = useRef(null);
  const mainCanvasRef = useRef(null);
  const segmentedCanvasRef = useRef(null);

  useEffect(() => {
    const drawBackgroundArc = () => {
      const canvas = backgroundCanvasRef.current;
      const ctx = canvas.getContext('2d');

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.beginPath();
      ctx.lineWidth = arcBackgroundLineWidth;
      ctx.strokeStyle = '#192d35'; // background color
      ctx.arc(arcX, arcY, arcBackgroundRadius, Math.PI, 2 * Math.PI);
      ctx.stroke();
    };

    drawBackgroundArc();
  }, []);

  useEffect(() => {
    const canvas = mainCanvasRef.current;
    const ctx = canvas.getContext('2d');

    let animationFrameId;

    const setColors = () => {
      const colors = [
        { primary: '#d92323', secondary: '#562d2d' },
        { primary: '#edbe00', secondary: '#4c5028' },
        { primary: '#6cd923', secondary: '#2d5630' },
      ];
      const index = percent < 33 ? 0 : percent < 66 ? 1 : 2;
      return {
        primary: colors[index].primary,
        secondary: colors[index].secondary,
      };
    };

    const setAngles = () => {
      const startAngleRad = Math.PI;
      const endAngleRad = startAngleRad + percent * 1.8 * (Math.PI / 180);
      return { startAngleRad, endAngleRad };
    };

    const { primary, secondary } = setColors();
    const { startAngleRad, endAngleRad } = setAngles();

    const draw = (startAngle, endAngle) => {
      // Draw primary arc
      ctx.beginPath();
      ctx.lineWidth = arcPrimaryLineWidth;
      ctx.strokeStyle = primary;
      ctx.arc(arcX, arcY, arcPrimaryRadius, startAngle, endAngle);
      ctx.stroke();

      // Draw secondary arc
      ctx.beginPath();
      ctx.lineWidth = arcSecondaryLineWidth;
      ctx.strokeStyle = secondary;
      ctx.arc(arcX, arcY, arcSecondaryRadius, endAngle, startAngle, true);
      ctx.stroke();
    };

    const animate = (incrementSize = 0.01) => {
      let progress = 0;
      const startAngle = startAngleRad;
      const endAngle = endAngleRad;

      const animateFrame = () => {
        progress += incrementSize;
        const currentAngle = startAngle + (endAngle - startAngle) * progress;

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        draw(startAngle, currentAngle);

        if (progress < 1) {
          animationFrameId = requestAnimationFrame(animateFrame);
        }
      };
      animationFrameId = requestAnimationFrame(animateFrame);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [width, percent]);

  useEffect(() => {
    const drawSegmentedArc = () => {
      const canvas = segmentedCanvasRef.current;
      const ctx = canvas.getContext('2d');

      const segmentCount = 5;
      const segmentAngle = Math.PI / segmentCount;
      const gapAngle = segmentAngle * 0.05; // Gap between segments

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < segmentCount; i++) {
        const startAngle = Math.PI + i * segmentAngle + gapAngle / 2;
        const endAngle = startAngle + segmentAngle - gapAngle;

        ctx.beginPath();
        ctx.lineWidth = segmentedArcLineWidth; // Width of the segmented arc
        ctx.strokeStyle = '#47575d'; // Color of the segmented arc
        ctx.arc(arcX, arcY, segmentedArcRadius, startAngle, endAngle);
        ctx.stroke();
      }
    };

    drawSegmentedArc();
  }, [arcWidth]);

  return (
    <div style={{ position: 'relative', height: arcHeight, width: arcWidth }}>
      <canvas
        ref={backgroundCanvasRef}
        width={arcWidth}
        height={arcHeight}
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
      <canvas
        ref={mainCanvasRef}
        width={arcWidth}
        height={arcHeight}
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
      <canvas
        ref={segmentedCanvasRef}
        width={arcWidth}
        height={arcHeight}
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
      <div
        style={{
          position: 'absolute',
          top: '80%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontFamily: 'Inter, sans-serif',
          fontSize: '24px',
          fontWeight: '700',
          color: 'rgba(245, 246, 247, 0.9)',
        }}
      >
        {percent}%
      </div>
    </div>
  );
};

const renderArcInElement = async (el) => {
  const width = el.getAttribute('data-width');
  const percentage = el.getAttribute('data-percentage');
  const root = createRoot(el);
  root.render(<Arc width={width} percent={percentage} />);
};

const renderArcs = () => {
  const arcElements = document.querySelectorAll('.app-graphs-radar');
  arcElements.forEach(renderArcInElement);
};

document.addEventListener('DOMContentLoaded', renderArcs);
